import { useQuery } from "react-query";
import { api } from "./interceptor";

interface QueryParams {
  [key: string]: string | boolean | number;
}

const getElements = async (url: string, queryParams?: QueryParams) => {
  const { data } = await api.get(url, {
    params: queryParams,
  });
  return data;
};

export const useGetQuery = (
  queryKey: string,
  url: string,
  queryParams?: QueryParams
) => {
  return useQuery(queryKey, () => getElements(url, queryParams), {
    refetchOnWindowFocus: false,
  });
};
