import { Autocomplete } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

export const CustomAutoComplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    transition: "0.2s ease-in-out all",

    borderRadius: "6px",
    backgroundColor: "#000B1808",
    height: "45px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    border: `1px solid ${alpha(theme.palette.primary.main, 1)}`,
    boxShadow: `${alpha(theme.palette.primary.main, 0.2)} 0 0 0 3px`,
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    border: `1px solid red`,
    boxShadow: `#F005 0 0 0 3px`,
  },

  "& .MuiInputLabel-root": {
    display: "none",
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 3px`,
      borderColor: theme.palette.primary.main,
    },
    "&:error": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 3px`,
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "0.9rem",
    padding: "11px 14px",
  },

  [theme.breakpoints.down("sm")]: {
    "& .MuiOutlinedInput-input": {
      fontSize: "0.8rem",
      padding: "10px 12px",
    },
  },
}));
