import { Box, Typography, useMediaQuery } from "@mui/material";
import { formatISODate } from "../../../../utils/format-date";
import { useMutateQuery } from "../../../../api/useMutateQuery";
import { LightTooltip } from "../../../../components/shared/ui/custom-tooltip/custom-tooltip";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useState } from "react";

const MessageContentCell = (props) => {
  const { params, setOpenPrevMessageModal, setClientId } = props;

  const isScreenLarge = useMediaQuery("(min-width: 1500px)");

  const [showNumber, setShowNumber] = useState(false);

  return (
    <Box
      sx={{
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
        wordBreak: "break-word",
        overflowWrap: "break-word",
        padding: "5px 0",
        fontSize: isScreenLarge ? "16px" : "14px",
        // fontWeight: "800",
      }}
    >
      {params?.content.replace(/(?<=\S)0(?=\S)/g, ' ')}
      {showNumber && (
        <Typography
          sx={{
            fontSize: isScreenLarge ? "16px" : "14px",
            direction: "rtl",
            textAlign: "left",
          }}
        >
          {params?.platform_type_name === "sms"
            ? params?.phone_number
            : params?.app_client_phone}
        </Typography>
      )}
      <Box sx={{ fontSize: isScreenLarge ? "14px" : "12px", color: "#2E73FF" }}>
        {formatISODate(params?.created_at)}
      </Box>
      <Box sx={{ cursor: "pointer" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <LightTooltip title={"عدد الرسائل الكلية/المقروءة"}>
            <Box
              onClick={() => {
                setOpenPrevMessageModal(true);
                setClientId(params?.client_id);
              }}
              sx={{
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                color: "#F8F8FB",
                backgroundColor: "#62C16C",
                borderRadius: "6px",
                padding: "4px",
                gap: "3px",
                width: "fit-content",
              }}
            >
              <Box
                sx={{
                  fontSize: "12px",
                  marginBottom: "-2px",
                }}
              >
                {params?.messageCount}/{params?.messageCountWithLiveRead}
              </Box>
            </Box>
          </LightTooltip>
          <LightTooltip title={"عدد الردود"}>
            <Box
              sx={{
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                color: "#F8F8FB",
                backgroundColor: "#B7A7FF",
                borderRadius: "6px",
                padding: "4px",
                gap: "3px",
                width: "fit-content",
              }}
            >
              <Box
                sx={{
                  fontSize: "12px",
                  marginBottom: "-2px",
                  marginLeft: "3px",
                }}
              >
                {params?.sendedMessagesCount}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ArrowCircleUpRoundedIcon sx={{ fontSize: "16px" }} />
              </Box>
            </Box>
          </LightTooltip>
          <LightTooltip title={"اظهار الرقم"}>
            <Box
              sx={{
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                color: showNumber ? "#F8F8FB" : "#629FC1",
                backgroundColor: showNumber ? "#629FC1" : "#F8F8FB",
                border: showNumber ? "1px solid #F8F8FB" : "1px solid #629FC1",
                borderRadius: "6px",
                padding: "4px",
                gap: "3px",
                width: "fit-content",
              }}
              onClick={() => {
                setShowNumber((prev) => !prev);
              }}
            >
              <Box
                sx={{
                  fontSize: "12px",
                  marginBottom: "-2px",
                  marginLeft: "3px",
                }}
              >
                عرض الرقم
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <RemoveRedEyeOutlinedIcon sx={{ fontSize: "16px" }} />
              </Box>
            </Box>
          </LightTooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default MessageContentCell;
